import {onDomReady} from "../../components/dynamic/observer";

class AudioPlayer {
  constructor(selector) {
    this.player = selector;
    this.isPlay = false;
    this.init();
    this.bind();
    this.update();
  }

  init() {
    this.initAudioObject();
    this.initButtons();
    this.initTiming();
    this.initRangeInput();
  }

  initAudioObject() {
    this.audioObj = this.player.querySelector('[data-audio-player-base]');
  }

  initButtons() {
    this.playPause = this.player.querySelector('[data-audio-player-play-pause]');
    this.rewindButtons = this.player.querySelectorAll('[data-audio-player-rewind]');
  }

  initTiming() {
    this.current = this.player.querySelector('[data-audio-player-current]');
    this.left = this.player.querySelector('[data-audio-player-left]');
  }

  initRangeInput() {
    this.range = this.player.querySelector('[data-range-input]');
    this.rangeProgress = this.player.querySelector('[data-audio-player-progress]');

    setTimeout(() => {
      this.range.max = Math.floor(this.audioObj.duration);
    }, 1000);
  }

  bind() {
    this.bindButtons();
    this.bindRange();
  }

  bindButtons() {
    this.bindPlayPause();
    this.bindRewinds();
  }

  bindPlayPause() {
    this.playPause.addEventListener('click', () => {
      if (!this.isPlay) {
        this.player.classList.add('_play');
        this.audioObj.play();
        this.isPlay = true;
      } else {
        this.player.classList.remove('_play');
        this.audioObj.pause();
        this.isPlay = false;
      }
    });

    this.bindDownUpEvents(this.playPause, '_mousedown');
  }

  bindRewinds() {
    this.rewindButtons.forEach((rewind) => {
      this.bindRewind(rewind);
    })
  }

  bindRewind(button) {
    const direction = button.dataset.direction;
    button.addEventListener('click', () => {
      if (direction === 'back') {
        this.audioObj.currentTime -= 15;
      } else if (direction === 'forward') {
        this.audioObj.currentTime += 15;
      }
    });

    this.bindDownUpEvents(button, '_mousedown');
  }

  bindDownUpEvents(el, className) {
    el.addEventListener('mousedown', (e) => {
      el.classList.add(className);
    });

    el.addEventListener('mouseup', (e) => {
      el.classList.remove(className);
    });

    el.addEventListener('touchstart', (e) => {
      el.classList.add(className);
    });

    el.addEventListener('touchend', (e) => {
      el.classList.remove(className);
    });
  }

  bindRange() {
    this.range.addEventListener('input', (e) => {
      this.setStyleToRange();
      this.audioObj.currentTime = parseInt(this.range.value, 10);
    });
  }

  setStyleToRange() {
    const min = this.range.min;
    const max = this.range.max;
    const val = this.range.value;
    this.rangeProgress.style.width = (val - min) * 100 / (max - min) + '%';
  }

  updateTiming() {
    const minutes = ('0' + Math.floor(Math.floor(this.audioObj.currentTime) / 60).toFixed(0)).slice(-2);
    const seconds = ('0' + (Math.floor(this.audioObj.currentTime) % 60).toFixed(0)).slice(-2);
    if (minutes && seconds) {
      this.current.innerHTML = minutes + ':' + seconds;
    }

    const timeLeft = Math.floor(this.audioObj.duration) - Math.floor(this.audioObj.currentTime);
    const durationMinutes = ('0' + Math.floor(Math.floor(timeLeft) / 60)).slice(-2);
    const durationSeconds = ('0' + Math.floor(timeLeft) % 60).slice(-2);
    if (durationMinutes && durationSeconds) {
      this.left.innerHTML = '-' + durationMinutes + ':' + durationSeconds;
    }
  }

  updateRangeProgress() {
    this.range.value = Math.floor(this.audioObj.currentTime);
    this.setStyleToRange();

    if (Math.abs(this.audioObj.currentTime) === Math.abs(this.audioObj.duration)) {
      this.audioObj.pause();
      this.audioObj.currentTime = 0;
      this.player.classList.remove('_play');
      this.isPlay = false;
    }
  }

  update() {
    this.updateTiming();
    this.updateRangeProgress();

    window.requestAnimationFrame(this.update.bind(this));
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-audio-player]')
    .forEach((el) => {
      const player = new AudioPlayer(el);
    });
})