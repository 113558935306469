import {onDomReady} from "../../components/dynamic/observer";
import Swiper, {Pagination} from "swiper";

onDomReady(() => {
  document.querySelectorAll('[data-audio-item-slider-wrap]')
    .forEach((item) => {
      const slider = new Swiper(item.querySelector('[data-audio-item-slider]'), {
        modules: [Pagination],
        loop: true,
        pagination: {
          el: item.querySelector('[data-audio-item-slider-pagination]'),
        },
      });
    })
})